(function ($, Drupal, window, document, undefined) {
  $(document).on('ready', function () {
    // Equalizador de altura div
    $('.col-eq').colequalizer();

    // Menu tercer nivel
    $('ul.dropdown-menu [data-toggle=dropdown]').on('click', function(event) {
      // Avoid following the href location when clicking
      event.preventDefault();
      // Avoid having the menu to close when clicking
      event.stopPropagation();
      // Re-add .open to parent sub-menu item
      $(this).parent().toggleClass('open');
      $(this).parent().find("ul").parent().find("li.dropdown").toggleClass('open');
    });

    $("#edit-submitted-email-cc-email-address", '.bk-footer-newsletter').attr('placeholder', 'Ingrese su correo electrónico');
  });

  /**
   * Scroll Up
   */
  Drupal.behaviors.scrollup = {
    attach: function (context) {
      $('body').once('scrollup', function () {
        $.scrollUp({
          animation: 'fade',
          scrollImg: true
        });
      });
    }
  };

  /**
   * Boton Menu Mobile
   */
  Drupal.behaviors.navbarToggle = {
    attach: function (context) {
      $('body').once('navbar-toggle', function () {
        $(".navbar-toggle").on("click", function (event, flag_login) {
          $(this).toggleClass("collapsed");
        });
      });
    }
  };

  /**
   * Popover calendario
   */
  Drupal.behaviors.popoverCalendar = {
    attach: function (context) {
      //$('body').once('popover-calendar', function () {
        var options = {
          placement: 'bottom',
          trigger: 'click',
          html: true
        };

        $('#accordion-calendar-events').find('[data-toggle=popover]').popover(options);

        $('#accordion-calendar-events').find('[data-toggle=popover]').on('click', function (e) {
          $('[data-toggle=popover]').not(this).popover('hide');
        });

        $(document).on("click", ".popover .popover-close", function () {
          $(this).parents(".popover").popover('hide');
        });
      //});
    }
  };

})(jQuery, Drupal, this, this.document);